<template>
  <div
    :id="targetField"
    uk-modal
    esc-close="true"
    bg-close="true"
  >
    <div 
      class="uk-modal-dialog uk-modal-body"
      :class="{ 'wider' : wide}"
    >
      <button
        class="uk-modal-close-default"
        type="button"
        uk-close
      />
      <img
        :class="{'wide': wide}"
        :src="targetPreview"
        alt="image preview"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    targetField: {
      required: true,
      type: String
    },
    targetPreview: {
      required: true,
      type: String
    },
    wide: {
      required: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.uk-modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wide {
  width: 1062px;
  height: auto;
}

.wider {
  width: auto;
  height: auto;
}
</style>
